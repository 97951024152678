import React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"



const Bridgestock = () => {
  //javascript
  const title = 'Bridge Stock (Simplified)';
  const metatitle = 'Bridge Stock (Simplified)';
  const description = 'Meta Description for Bridge Stock (Simplified)';
  const metadescription = description;
  return(
    <Layout bodyclass="bridge-stock" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <p></p>
      
    </Layout>
  )
}

export default Bridgestock
